export default [
    {
        name: 'personalIndex',
        path: '/personalIndex',
        component: () => import(/* webpackChunkName: "IndexPersonal" */ '../components/personal/IndexPersonal.vue'),
        meta: {
            title: 'Funcionarios.',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Mae', 'Analista UCO', 'Recursos Humanos', 'Jefatura UCO', 'Jefatura USP', 'Jefatura UAF'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },

    {
        name: 'addfuncionario',
        path: '/addfuncionario',
        component: () => import(/* webpackChunkName: "AddFuncionario" */ '../components/personal/AddFuncionario.vue'),
        meta: {
            title: 'Agregar Funcionario',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Mae', 'Analista UCO', 'Recursos Humanos', 'Jefatura UCO', 'Jefatura USP', 'Jefatura UAF'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },

    {
        name: 'asignaciones',
        path: '/asignaciones',
        component: () => import(/* webpackChunkName: "IndexAsignaciones" */ '../components/asignaciones/index.vue'),
        meta: {
            title: 'Asignaciones.',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Mae', 'Analista UCO', 'Recursos Humanos', 'Jefatura UCO', 'Jefatura USP', 'Jefatura UAF'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },

    {
        name: 'asignacionesuco',
        path: '/asignacionesuco',
        component: () => import(/* webpackChunkName: "IndexAsignaciones" */ '../components/asignacionesuco/index.vue'),
        meta: {
            title: 'Asignaciones Seguimiento.',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Mae', 'Analista UCO', 'Recursos Humanos', 'Jefatura UCO', 'Jefatura USP', 'Jefatura UAF'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },

    {
        name: 'vacacacionesCertificador',
        path: '/vacacacionesCertificador',
        component: () => import(/* webpackChunkName: "IndexVacaciones" */ '../components/vacaciones/index.vue'),
        meta: {
            title: 'Vacaciones.',
            middleware: 'auth',            
            middlewareRol: ['Super Admin', 'Analista UCO', 'Certificador', 'Responsable', 'Asistente', 'Jefatura USP'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },

    {
        name: 'vacacacionesRevision',
        path: '/vacacacionesRevision',
        component: () => import(/* webpackChunkName: "IndexRevision" */ '../components/vacaciones/revision.vue'),
        meta: {
            title: 'Vacaciones.',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Mae', 'Analista UCO', 'Recursos Humanos', 'Jefatura UCO', 'Jefatura USP', 'Jefatura UAF'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },

    {
        name: 'tramitesAsignaciones',
        path: '/tramitesAsignaciones',
        component: () => import(/* webpackChunkName: "RevisionDdjj" */ '../components/tramites/tramitesAsignaciones.vue'),        
        meta: {
            title: 'Tramites Pendientes',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Mae', 'Analista UCO', 'Recursos Humanos', 'Jefatura UCO', 'Jefatura USP', 'Jefatura UAF'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },

    {
        name: 'pendientesDetalle',
        path: '/pendientesDetalle',
        component: () => import(/* webpackChunkName: "RevisionDdjj" */ '../components/tramites/pendientesDetalle.vue'),        
        meta: {
            title: 'Tramites Pendientes Detalle',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Mae', 'Analista UCO', 'Recursos Humanos', 'Jefatura UCO', 'Jefatura USP', 'Jefatura UAF'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },

]
